import React from 'react';
import cx from 'classnames';
import Layout from 'components/Layout';
import SEO from 'components/seo';

import Button from 'components/Button';
import styles from './programs.module.scss';

const ProgramsPage = () => (
  <Layout className={styles.page}>
    <SEO title="Programs" />
    <div className={cx(styles.root, 'content')}>
      <div className="twoColumnLayout">
        <h1 className="title">
          We offer a STEAM-oriented curriculum, and also hold frequent student-led research & innovation symposiums, 
          to ignite a passion for STEAM - geared towards providing African solutions to African problems.
        </h1>
        {/* <div>
          <div className="videoWrapper">
            <iframe
              title="GEWS Programs"
              src="https://player.vimeo.com/video/672029511?title=0&byline=0&portrait=0&color=00e461"
              frameBorder="0"
              allow="autoplay; fullscreen"
            />
          </div>
        </div> */}
      </div>
    </div>

    <section className={cx(styles.programsWrapper)}>
      <div className={cx(styles.programsCard)}>
        <h2>1:1 Waldorf School</h2>
        <p>
        GE-WS will use the Waldorf pedagogical approach to teaching and learning. The Waldorf pedagogy seeks to educate a student’s head, heart and hands.
        In alignment with the Waldorf approach to teaching and learning, GE-WS will allow students to experience and experiment a phenomenon first before they are introduced to the theory to the studied.
        GE-WS students will be reflect, independent learners who will also thrive at collaborative work. Also with the Waldorf approach to teaching and learning, students are encouraged to respect and take care of their environment. 
        Waldorf imparts skills in gardening, farming, food production, and in basic care of the plants and animals, encouraging students to spend time in nature. As such the ideal place for a Waldorf school in right in the midst of nature. 
        GE-WS will provide its students with a natural 100 acre self-sustaining eco-park, and create a natural laboratory for experimenting biodynamic principles steered towards firsthand experience with environmental sustainability.
        GE-WS will furthermore prohibit the presence of plastics within the school community, as the use of natural materials will be promoted. This way our future African Climate Change leaders will understand the importance of policy making and implementation for environmental protection..
        </p>
        {/* <Button href="https://docs.google.com/forms/d/e/qg/viewform">
          Apply to become a mentee/mentor
        </Button> */}
      </div>

      <div className={cx(styles.programsCard)}>
        <h2>African Multilingual Education</h2>
        <p>
          A 16-week program that introduces artists from communities impacted by
          incarceration to technology through therapeutic writing practices,
          music/video production, and audio engineering. The program provides
          participants with a fundamental working-knowledge of DAW's, music
          production, and song writing. TRAP teaches participants the requisite
          web development skills to build a web page that showcases the skills
          they gain recording with professional audio engineers and learning the
          practices of therapeutic songwriting. The program culminates with
          participants conceptualizing, designing, and developing a website to
          showcase the music they create during the program which is informed by
          these practices.
        </p>
        {/* <Button
          className={cx(styles.applyLink)}
          href="https://docs.google.com/forms/d/e/dQ/viewform"
        >
          Apply to become a T.RAP mentee
        </Button> */}
      </div>
    </section>

    <section className={cx(styles.contentSectionWrapper)}>
      <div className={cx(styles.contentSectionCopy)}>
        <h2>The AfCFTA Opportunity </h2>
        <p>
        Africa is coming together, through various interventions under the African Union, in order to improve the overall quality of life on the continent. As a result, forty-four countries on the continent on March 21, 2018 signed the African Continental Free Trade Area (AfCFTA) agreement which when fully rolled out, will remove colonial borders off the continent, and allow the free flow of goods, services and people across the region.    
        GE-WS will be a change agent, to help foster togetherness amongst the brightest of young African women, who will grow together, speak the same languages, and eventually work towards promoting climate change on the African Continent.  
        GE-WS will target gifted but disadvantaged girls from Ghana, Benin, Cape Verde, Ivory Coast, Gambia, Guinea, Liberia, Mauritania, Sierra Leone, Congo DR, Angola and other African countries.
        GE-WS will actively promote the study of African commonest languages & cultural integration in its curriculum that will also promote of African values, ethics, music, including African indigenous musical instruments, fashion, dance, food and culture in general
        </p>
      </div>

      <div className={cx(styles.contentSectionContent)}>
        <div className="videoWrapper">
          <iframe
            title="GEWS 2021 Summer 1:1 Showcase Recap"
            className="youtubeVideo"
            src="https://www.youtube.com/embed/xxxx"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>
      </div>
    </section>

    <section className={cx(styles.contentSectionWrapper)}>
      <div className={cx(styles.contentSectionCopy)}>
        <h2>Carbon-Neutral living</h2>
        <p>
          The GE-WS project has a strong alignment with sustainable development approach and environment friendly provisions have been accordingly planned within the campus.
          Additionally, students at GE-WS will be taught to live carbon neutral. The entire school community will also walk-the-talk through various interventions including, but not limited to- the following:
        </p>
      </div>

      <div className={cx(styles.programsCard)}>
        <h2>Child Protection and other Policies</h2>
        <p>
          GE-WS will have a state-of-the-art school campus that promotes quality education, transparency, health and safety.
          <p>
            GE-WS will issue a comprehensive Child Protection Policy that will be made available to all teaching staff, 
            school administration, supportive staff, student observers, interns, researchers, volunteers, board members, collaborating community groups, 
            donors and funding bodies, consultants, vendors, contractors, and all students that come in contact with the school; all such persons will need to sign a code of conduct, and pass an annual criminal history check.
            These checks will help to promote a safe and caring environment for all students at GE-WS
          </p>
        </p>
        {/* <Button
          className={cx(styles.applyLink)}
          href="https://docs.google.com/forms/d/e/viewform"
        >
          Read More on Child Protection
        </Button> */}
      </div>
    </section>
  </Layout>
);

export default ProgramsPage;
